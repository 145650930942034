@import "../styles/variables.scss";
@import "../styles/mixins.scss";


.filterContainer{
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    h4{
        font-family: Inter;
        font-weight: 500;
        font-size: 20px;
        padding: 10px;
        border-bottom: 1px solid $greyStroke;
    }

    .subHeading{
        font-weight: 600;
        font-size: 14px;
    }

    .locationContainer{
        padding: 8px 10px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        height: 70px;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid $greyStroke;
    }

    .checkboxContainer{
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        // height: 219px;
        gap: 15px;
    }

    .ratingContainer{
        padding: 8px 10px;
        border-bottom: 1px solid $greyStroke;

        .ratingGroup{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }

    }

    .priceRangeContainer{
        padding: 8px 10px;
        border-bottom: 1px solid $greyStroke;

        .priceOptionContainer{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
        }

        .goButton{
            width: 30%;
            font-size: 14px;
            border-radius: 20px;
            background-color: #3B7358;
            color: white;
            font-weight: 600;
          }
    }

    .optionContainer{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

    .goButton{
        width: 30%;
        font-size: 14px;
        border-radius: 20px;
        background-color: #3B7358;
        color: white;
        font-weight: 600;
      }

    .container{
        padding: 8px 10px;
        border-bottom: 1px solid $greyStroke;
        display: flex;
        flex-direction: column;
        gap: 13px;
    }

    .serviceContainer{
        padding: 8px 10px;
        border-bottom: 1px solid $greyStroke;

        .serviceGroup{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
    }

    .buttonContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 15px;
        padding: 10px;
    }
}