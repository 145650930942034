h1 {
    font-family: 'Arbutus Slab', serif;
    font-size: 43px;
    color: #000;
}

h1,h2,h3,h4,h5,h6,p{
    margin-bottom: 0px;
}

.mainBtn {
    background-color: black;
    color: #fff !important;
    width: fit-content;
    padding: 8px 20px !important;
    border-radius: 40px;
}

.navbar-brand img {
    width: 200px;
}

.sticky{
    position: fixed;
    top: 0;
    width: 100%;
}

nav .nav-link {
    margin: 0px 10px;
    color: #000;
}

.App nav {
    background-color: #3B7358;
    color: #fff;
}

/* hero section starts */

.heroSection {
    background-image: url(../assets/images/bnrBg.png);
    background-size: contain;
    background-position: center;
}

.banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0px;
}

.bannerLeft,
.bannerRight {
    width: 45%;
    text-align: left;
}

.bannerRight img {
    width: 100%;
}

.bannerLeft .mainBtn {
    margin-top: 30px;
}

.bannerLeft p {
    color: #161616;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
}

/* hero section ends */

/* about section starts */

.aboutSection {
    background-color: #000;
    padding: 120px 0px;
}

.aboutOuter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.aboutBox {
    border-radius: 24px;
    background: radial-gradient(70.71% 70.71% at 50% 50%, #0D0C0C 0%, #000 100%);
    box-shadow: 0px 0px 60px 0px rgba(59, 115, 88, 0.50);
    border: 1px solid #3B7358;
    width: 32%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 30px;
}

.aboutBox img {
    width: 70px;
    margin-bottom: 10px;
}

.aboutLftRytInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 120px 0px;
}

.aboutLftRytInner img {
    width: 100%;
}

.aboutLftRytLeft,
.aboutLftRytRight {
    width: 40%;
    text-align: left;
}

h4 {
    font-family: 'Arbutus Slab', serif;

}

.aboutLftRytInner h4 {
    margin-bottom: 15px;
}

p {
    color: #B0B0B0;
}

.serviceOuter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;
}

.serviceBox {
    border-radius: 24px;
    border: 1px solid #6CA78B;
    background: radial-gradient(70.71% 70.71% at 50% 50%, #538C71 0%, #3B7358 100%);
    border: 1px solid #6CA78B;
    width: 32%;
    padding: 0px 20px;
    margin: 15px 0px;
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.serviceBox img {
    width: 70px;
    margin-bottom: 15px;
}

.serviceSection {
    padding: 120px 0px;
}

.serviceBox p {
    color: #0D0C0C;
}




/* img {
    max-width: 150px;
    height: 5rem;
    padding: 0 15px;
    object-fit: cover;
  } */

/* img:last-of-type {
    padding-left: 0;
  } */

.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 20rem;
}

.wrapper {
    position: absolute;
    display: flex;
}

section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}


.appShowSection {
    padding: 120px 0px;
    background-image: url(../assets/images/checkBg.png);
    background-size: contain;
    overflow: hidden;
    background-position: center;
    background-color: #000;
}


.appShowOuter {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: nowrap;
}

.appShowOuter {
    position: relative;
    z-index: 2;
}

.appShowOuter::before {
    content: "";
    position: absolute;
    bottom: -30px;
    border-radius: 524px;
    background: #181818;
    filter: blur(23.5px);
    width: 100%;
    height: 100px;
    z-index: -1;
}

.appShowImage1 {
    width: 220px;
    margin-right: -4%;
    margin-bottom: 20px;
}

.appShowImage2 {
    width: 250px;
}

.appShowImage3 {
    width: 350px;
    margin: 0px 4%;
}

.appShowImage4 {
    width: 250px;
    position: relative;
    z-index: 2;
}

.appShowImage5 {
    width: 220px;
    margin-left: -4%;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
}


.contactSection {
    border-radius: 24px;
    border: 1px solid #3B7358;
    background: radial-gradient(70.71% 70.71% at 50% 50%, #0D0C0C 0%, #000 100%);
    box-shadow: 0px 0px 100px 0px rgba(59, 115, 88, 0.50);
    margin-top: 150px;
    padding: 80px 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.contactImg {
    width: 35%;
}

.contactForm {
    width: 40%;
}

.contactInput {
    background-color: transparent;
    border-bottom: 1px solid #fff !important;
    border: none;
    border-radius: 0px;
}

.contactLable {
    color: #fff;
}

.contactInput:focus {
    background-color: transparent;
    box-shadow: none;
    outline: none;
}

.contactInput:focus .contactLable {
    background-color: transparent;
    background: transparent;
}

.form-control:focus {
    background-color: transparent;
    color: #fff;
}

.contactImg img {
    width: 100%;
}


footer {
    width: 100%;
    box-shadow: 0px -4px 70px 0px #3B7358;
    padding: 3rem 0px 1rem 0px;
    background-color: #000;
}

.footerLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding-left: 0px !important;
    margin-top: 30px;
}


.footerLinks li {
    margin: 0px 20px;
}

.BtnWhite {
    background-color: #fff;
    color: #000 !important;
    margin-top: 30px;
}

.footerLinksItem a {
    color: #e6e6e6;
}

.footerLogo {
    color: #e6e6e6;
    margin-bottom: 40px;
}

.footerLogo a img {
    width: 200px;
}

.appDownloadBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 100px;
}

.appDownloadBtn a img {
    width: 200px;
    margin: 0px 10px;
}

.footerLinksItem a:hover {
    color: #3B7358;
}

@media (max-width:480px) {

    /* hero section media queries starts */
    .banner {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 1rem 0px;
        box-sizing: border-box;
        gap: 1.5rem;
    }

    .bannerLeft,
    .bannerRight {
        width: 100%;
        text-align: left;
    }

    .bannerLeft h1{
        font-size: 1.7rem;
    }

    .bannerLeft p {
        color: #161616;
        margin-top: 20px;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .bannerRight img {
        width: 100%;
        /* border: 2px solid blue; */
        margin: 0 auto;
        text-align: center;
    }

    /* hero section media queries ends */
    
    /* About section media queries starts */
    .aboutSection {
        background-color: #000;
        padding: 1.3rem 0px;

    }
    

    .aboutOuter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .aboutBox {
        border-radius: 24px;
        background: radial-gradient(70.71% 70.71% at 50% 50%, #0D0C0C 0%, #000 100%);
        box-shadow: 0px 0px 60px 0px rgba(59, 115, 88, 0.50);
        border: 1px solid #3B7358;
        width: 80vw;
        height: 80%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        /* gap: 0.5rem; */
        text-align: center;
    }

    .aboutBox img {
        width: 4rem;
    }

    h4 {
        font-family: 'Arbutus Slab', serif;

    }
    
    .aboutLftRytInner h4 {
        margin-bottom: 15px;
    }
    
    p {
        color: #B0B0B0;
        font-size: 1rem;
    }

    .aboutLftRytOuter{
        display: flex;
        flex-direction: column;
        margin: 1rem 0px;
        gap: 2rem;
    }
    
    .aboutLftRytInner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 0px;
    }
    
    .aboutLftRytLeft,
    .aboutLftRytRight {
        width: 100%;
        text-align: justify;
        padding: 1rem 0.7rem;
    }

    .aboutRightMobile{
        display: flex;
        flex-direction: column-reverse;
        /* border: 2px solid red; */
    }

    .aboutLftRytInner img {
        width: 100%;
    }
    
    /* About section media queries end */

    /* Service Section queries starts */

    .serviceSection {
        padding: 1.5rem 0px;
    }
    
    
    .serviceOuter {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0px;
    }

    .serviceBox{
        width: 90%;
        margin: 0px;
        border-radius: 24px;
        border: 1px solid #6CA78B;
        background: radial-gradient(70.71% 70.71% at 50% 50%, #538C71 0%, #3B7358 100%);
        border: 1px solid #6CA78B;
        /* width: 32%; */
        padding: 1.5rem 0.7rem;
        margin: 0.7rem 0px;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    /* download button media queries */

    .appShowSection {
        padding: 6rem 0px 1rem 0px;
        background-image: url(../assets/images/checkBg.png);
        background-size: contain;
        overflow: hidden;
        background-position: center;
        background-color: #000;
    }

    .appDownloadBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 2rem 0px;
        gap: 1rem;
    }
    
    .appDownloadBtn img {
        width: 80%;
        margin: 1rem 0;
        /* height: 10vh; */
    }

    /* contact form media queries starts */
    .contactSection {
        border-radius: 24px;
        border: 1px solid #3B7358;
        background: radial-gradient(70.71% 70.71% at 50% 50%, #0D0C0C 0%, #000 100%);
        box-shadow: 0px 0px 100px 0px rgba(59, 115, 88, 0.50);
        margin: 3rem 0;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
    
    }
    
    .contactImg {
        width: 50%;
    }
    
    .contactForm {
        display: flex;
        flex-direction: column;
        width: 80%;
        /* height: 70vw; */
    }

    /* footer media queries starts */

    .footerLinks {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style: none;
        padding-left: 0px !important;
        margin-top: 30px;
    }

    .footerLinksSocial{
        flex-direction: row;
    }
}


@media only screen and (min-width: 481px) and (max-width: 600px){
    
    .aboutOuter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .aboutBox {
        border-radius: 24px;
        background: radial-gradient(70.71% 70.71% at 50% 50%, #0D0C0C 0%, #000 100%);
        box-shadow: 0px 0px 60px 0px rgba(59, 115, 88, 0.50);
        border: 1px solid #3B7358;
        width: 80vw;
        height: 80%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        /* gap: 0.5rem; */
        text-align: center;
    }



    .aboutLftRytOuter{
        display: flex;
        flex-direction: column;
        margin: 1rem 0px;
        padding: 0 1rem;
        /* gap: 1rem; */
    }

    .serviceOuter {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 70px;
    }
    
    .serviceBox {
        border-radius: 24px;
        border: 1px solid #6CA78B;
        background: radial-gradient(70.71% 70.71% at 50% 50%, #538C71 0%, #3B7358 100%);
        border: 1px solid #6CA78B;
        width: 32%;
        padding: 10px 20px;
        margin: 15px 0px;
        height: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
    .serviceBox img {
        width: 60px;
        margin-bottom: 15px;
    }
    
    .serviceSection {
        padding: 80px 0px;
    }

    .serviceBox h4 {
        color: #0D0C0C;
        font-size: 1.3rem;
    }

    .serviceBox p {
        color: #0D0C0C;
        line-height: 1.3rem;
    }
}


@media only screen and (min-width: 601px) and (max-width: 991px){
    

    .aboutSection {
        background-color: #000;
        padding: 100px 0px;
        /* border: 2px solid yellow; */
    }

    .aboutOuter {
        /* width: 90vw; */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1rem;
        padding: 0px 2rem;
        margin: 0px auto;
        /* border: 2px solid red; */
    }
    
    .aboutBox {
        border-radius: 24px;
        background: radial-gradient(70.71% 70.71% at 50% 50%, #0D0C0C 0%, #000 100%);
        box-shadow: 0px 0px 60px 0px rgba(59, 115, 88, 0.50);
        border: 1px solid #3B7358;
        width: 50%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .aboutBox img {
        width: 4rem;
        margin-bottom: 10px;
    }

    .serviceSection {
        padding: 80px 0px;
        width: 100%;
        /* border: 2px solid blue; */
    }

    .serviceContainer{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
    }

    .serviceOuter {
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        justify-content: space-between;
        margin-top: 70px;
        width: 90%;
        /* border: 2px solid red; */
    }
    
    .serviceBox {
        border-radius: 24px;
        border: 1px solid #6CA78B;
        background: radial-gradient(70.71% 70.71% at 50% 50%, #538C71 0%, #3B7358 100%);
        border: 1px solid #6CA78B;
        width: 32%;
        padding: 10px 20px;
        margin: 15px 0px;
        height: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
    .serviceBox img {
        width: 60px;
        margin-bottom: 15px;
    }
    
   

    .serviceBox h4 {
        color: #0D0C0C;
        font-size: 1.3rem;
    }

    .serviceBox p {
        color: #0D0C0C;
        line-height: 1.3rem;
    }
}