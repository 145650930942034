.outerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    // z-index: 2;
    top: 0;
    left: 0;
}