@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  // @include flex-start-center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 80vh;
  font-family: Inter;

  span {
    font-weight: 600;
    font-size: 16px;
  }

  .leftCard {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 28%;
    height: 70%;
    border: 1px solid $greyStroke;
    border-radius: 16px;
    padding: 0.8rem;

    .service {
      padding: 0.3rem 0px;
      border-bottom: 1px solid $greyStroke;
      font-size: 12px;
    }
  }
  .rightCard {
    width: 70%;
    height: 70%;
    border: 1px solid $greyStroke;
    border-radius: 16px;
    padding: 0.8rem;

    .allCards {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.cardContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $greyStroke;
  height: 70%;
  width: 32%;
  padding: 0.5rem;
  border-radius: 17px;
  box-shadow: 0px 3px 17px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
  }

  span {
    font-size: 14px;
    font-weight: 400;
  }

  .packageDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px auto;
    width: 92%;
    height: 55%;
    gap: 10px;

    img {
      height: 80%;
      width: auto;
    }

    .text {
      display: flex;
      flex-direction: column;

      .textHeading {
        font-size: 12px;
        color: rgba(28, 28, 28, 0.4);
      }

      .textContent {
        font-size: 12px;
        color: rgba(28, 28, 28, 1);
      }
    }

    .box {
      display: flex;
      align-items: flex-start;
      height: 25%;
      gap: 5px;
    }
  }
}
