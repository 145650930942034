@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.outerContainer {
  border: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 15px;
  padding: 1rem;
  border-radius: 16px;
  .heading {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
  }
}

.reviewContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
}

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  width: 100%;

  img {
    padding: 1rem;
    height: 100%;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 5px;
    h6 {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
    }

    .rating {
      display: flex;
      font-family: Gilroy;
      font-weight: 600;
    }
  }
}

.reviewCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  padding: 1rem 1rem;
  gap: 10px;

  .profile {
    display: flex;
    gap: 10px;

    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      h6 {
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
      }

      h5 {
        color: #676767;
        font-size: 12px;
      }
    }
  }
}
