// color variables

$primeryColor: #3B7358;
$textHeading: #1C1C1C;
$textgrey: #9c9c9c;
$textPara: #484964;
$lightGreen: #E4F3EC;
$midLightGreen: #CAEADC;
$greenstroke: #B7DBCC;
$greyStroke: #EEEEF2;
$cardGrey: #f9f9fcb4;
$cardBlue: #E3F5FF;
$cardPurple: #E5ECF6;
$whiteColor: #ffffff;
$borderColor: #E7E7E7;



// font variables

$fs24: 24px;
$fs16: 16px;
$fs14: 14px;
$fs12: 12px;


