@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.DBdataGridBox {
  width: 100%;

  .DBdataGrid {
    width: 100%;

    thead {
      background-color: #f9f9fc;
      border: 1px solid $greyStroke;
      tr {
        th {
          font-weight: 500;
          font-size: 14px;
          padding: 8px;
          border: 1px solid $greyStroke;
        }
      }
    }

    tbody {
      tr {
        background-color: #f9f9fc;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: $cardGrey;
        }

        td {
          font-weight: 400;
          font-size: 14px;
          padding: 8px;
          border: 1px solid $greyStroke;
        }

        .male {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #dbe9ff;
          color: #3273d6;
          padding: 2px 10px;
          margin: 5px 15px;
          border-radius: 20px;
        }
        .female {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f0d6e1;
          color: #bf4d7d;
          padding: 2px 10px;
          margin: 5px 15px;
          border-radius: 20px;
        }
        .unisex {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ebdbff;
          color: #7a32d6;
          padding: 2px 10px;
          margin: 5px 15px;
          border-radius: 20px;
        }
      }
    }
  }
}
.completed {
  color: #3b7358;
}

.inProcess {
  color: #2b73db;
}

.cancelled {
  color: #e75839;
}

.sr {
  width: 5%;
  text-align: center;
}

.name {
  width: 25%;
}
.status {
  width: 20%;
}

.scroll{
  width: 100vw;
  overflow-x: scroll;
}