@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.header {
  @include flex-between-center;
  border-bottom: 1px solid $greyStroke;
  padding: 10px 15px;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #fff;

  .pageHeading {
    color: $primeryColor;
    font-size: $fs16;
    font-weight: 600;
  }

  .headerActions {
    display: flex;
    gap: 5px;
    align-items: center;

    .adminBtn {
      padding: 5px 20px;
      background-color: $primeryColor;
      color: #fff;
      border-radius: 30px;
      font-family: Inter;
      font-weight: 400;
      height: 100%;
      font-size: 14px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 5px;
      background-color: $lightGreen;
    }

    img {
      width: 22px;
      height: 100%;
    }
    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 100%;

      img {
        width: 40px;
      }
    }
  }
}

.box {
  display: flex;
  align-items: center;
  background-color: #fff ;
  border-radius: 6px;
  padding: 1px 5px;
  border: 1px solid $greyStroke;
  width: 300px;

  input {
    border: 0px;
    background-color: #fff;
    width: 100%;
    color: $textPara;
  }

  input:focus {
    outline: none;
    border: none;
  }

  input:hover {
    border: none;
  }
}

.boxIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}