@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.midData {
  padding: 20px;

  .shopProfile {
    @include flexProp(column, center, flex-start, 20px, 0px, 0px, 0px, 0px);

    .ShopProfileBox {
      @include flexProp(column,
        flex-start,
        center,
        20px,
        20px,
        20px,
        60px,
        20px);
      border-radius: 20px;
      background: $whiteColor;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      width: 30%;
      position: relative;
      height: 500px;

      img {
        width: 120px;
      }

      .shopName {
        h5 {
          font-size: 20px;
        }
      }

      .shopFeatureList {
        @include flexProp(row, space-between, center, 20px, 0px, 0px, 0px, 0px);

        .shopFeature {
          @include flexProp(column, center, center, 0px, 0px, 0px, 0px, 0px);
        }
      }

      .shopBntBox {
        @include flexProp(row, center, center, 20px, 0px, 0px, 0px, 0px);
      }

      .shopProfileFooter {
        background-color: $cardGrey;
        padding: 5px 0px;
        position: absolute;
        bottom: 0px;
        width: 100%;

        button {
          width: 100%;
          display: block;
          text-align: center;
          color: $textgrey;
          font-weight: 600;
          transition: 0.3s;

          &:hover {
            color: $primeryColor;
          }
        }
      }
    }

    .ShopDetailBox {
      @include flexProp(column,
        flex-start,
        center,
        20px,
        20px,
        20px,
        20px,
        20px);
      border-radius: 20px;
      background: $whiteColor;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      width: 50%;
      height: 500px;

      h5 {
        text-align: left;
        width: 100%;
      }

      .shopDetailList {
        @include flexProp(row, space-between, center, 0px, 0px, 0px, 0px, 0px);
        flex-wrap: wrap;
        width: 100%;

        .shopDetailListItem {
          @include flexProp(row,
            flex-start,
            center,
            10px,
            10px,
            0px,
            10px,
            0px);
          width: 46%;
          border-right: 1px solid $greyStroke;

          span {
            p {
              color: $textPara;
            }
          }

          &:nth-child(even) {
            border-right: none;
          }

          .shopDetailIcon {
            @include flexProp(row, center, center, 0px, 0px, 0px, 0px, 0px);
            width: 26px;
            height: 26px;
            background-color: $lightGreen;
            border-radius: 8px;
            color: $primeryColor;
          }
        }
      }
    }
  }

  .formContainer {
    margin-top: 20px;
    width: 50%;
    height: 435px;

    .formHeading {
      margin-bottom: 20px;
    }

    input {
      width: 100%;
    }

    .secondRow {
      display: flex;
      justify-content: space-between;
      margin: 15px 0px;

      .Email,
      .PhoneNumber {
        width: 48%;
      }
    }

    .thirdRow {
      display: flex;
      justify-content: space-between;
      margin: 15px 0px;

      .Pincode {
        width: 30%;
      }

      .Address {
        width: 66%;
      }
    }

    .fourthRow {
      margin: 15px 0px;

      .options {
        display: flex;
        justify-content: space-between;
        width: 70%;

        .Male,
        .Female,
        .Both {
          display: flex;
          align-items: center;
          gap: 10px;

          input {
            height: 16px;
            width: 16px;
            accent-color: $primeryColor;
          }
        }
      }
    }

    .buttons {
      display: flex;
      width: 40%;
      justify-content: space-between;

      .primaryButton {
        @include flexProp(row, center, center, 10px, 0px, 0px, 0px, 0px);
        background-color: $primeryColor;
        border-radius: 36px;
        color: $whiteColor;
        padding: 6px 20px;
      }

      .secondaryButton {
        @include flexProp(row, center, center, 10px, 0px, 0px, 0px, 0px);
        background-color: $lightGreen;
        border-radius: 36px;
        color: $primeryColor;
        padding: 6px 20px;
        font-weight: 600;
      }
    }
  }
}

.profileBoxBg {
  width: 100%;
  height: 200px;
  background-color: $primeryColor ;
}

.profileboxsContainer{
  display: flex;
  justify-content: center;
  position: relative;
  top: -100px;
  gap: 30px;
}