@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

ul {
  list-style: none;
  padding-left: 0px;
}

a {
  text-decoration: none;
}

button {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.middleData {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100vh;
}

.main {
  @include flex-center-horiz;
  height: 100vh;

  .dashboardMid {
    width: 65%;
    height: 100%;
  }

  .dashboardData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 95%;

    .dashboardMain {
      width: 100%;
      margin: auto;
      .dashboardStats {
        @include flex-between-center;

        .dashboardStatsBox {
          // @include flex-center;
          @include flex-coloumn-center-start;
          width: 24%;
          border-radius: 16px;
          padding: 24px;

          span {
            font-weight: 500;
            height: 20px;
            font-family: Inter;
          }

          span:first-child {
            font-size: $fs16;
          }

          span:last-child {
            font-size: $fs24;
            font-weight: 700;
          }

          &:nth-child(odd) {
            background-color: $cardBlue;
          }

          &:nth-child(even) {
            background-color: $cardPurple;
          }
        }
      }
    }

    .tables {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .shopApproval {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        border: 1px solid $greyStroke;
        border-radius: 16px;
        padding: 10px 10px;
        gap: 12px;

        .heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          font-family: Inter;

          span {
            font-weight: 600;
            font-size: 15px;
          }

          button {
            color: $primeryColor;
            border-radius: 20px;
            padding: 6px 14px;
            background-color: $lightGreen;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.photos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  img{
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
}
