@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  padding: 1rem;
  border: 1px solid $greyStroke;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 100%;
  justify-content: flex-start;
  gap: 20px;

  .upperHeadings{
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 600;
      font-size: 16px;
    }
  }
  
  .childCardContainer {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: space-between;
  }
}

.outerCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 90%;
  width: 49%;
  border: 1px solid $greyStroke;
  border-radius: 6px;
  padding: 1.5rem 1rem;
  font-style: Inter;

  .cardContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    width: 100%;

    .leftSide {
      height: 90%;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 25px;
      .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        img {
          width: 80%;
        }

        .details {
          text-align: center;

          h2 {
            font-size: 24px;
            font-weight: 500;
          }

          .location {
            display: flex;
            justify-content: space-between;
            img {
              width: 10%;
            }
            h6 {
              color: #a3aed0;
              font-size: 14px;
            }
          }
        }
      }
      .lowerBox {
        display: flex;
        justify-content: center;
        gap: 30px;

        .child {
          text-align: center;

          span {
            color: #a3aed0;
            font-size: 14px;
            font-weight: 400;
          }

          h6 {
            font-weight: 700;
            font-size: 22px;
          }
        }
      }
    }

    .line {
      height: 90%;
      background-color: $greyStroke;
      width: 1px;
      align-items: flex-start;
    }

    .rightSide {
      height: 90%;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: flex-start;
      gap: 15px;

      span {
        font-weight: 500;
        color: rgba(28, 28, 28, 1);
      }

      .detailsBox {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        img {
          height: 60%;
        }

        span {
          color: rgba(28, 28, 28, 0.4);
          font-size: 12px;
        }
        h6 {
          font-size: 13px;
        }
        .rightDescription {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
    }
  }

  .buttonBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;

    .location {
      padding: 0.3rem 1rem;
      background-color: #e4f3ec;
      border-radius: 50px;
      color: $primeryColor;
    }
    .dashboard {
      padding: 0.3rem 1rem;
      background-color: $primeryColor;
      border-radius: 50px;
      color: #ffffff;
      font-weight: 600;
    }
  }
}
