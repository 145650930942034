@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.outerCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 60vh;
    width: 70vh;
    border: 2px solid $greyStroke;
    // border: 2px solid red;
    background-color: #fff;
    border-radius: 6px;
    padding: 1.5rem 1rem;
    font-style: Inter;
  
    .cardContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      width: 100%;
  
      .leftSide {
        height: 90%;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 25px;

        .profile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
  
          img {
            width: 80%;
          }
  
          .details {
            text-align: center;
  
            h2 {
              font-size: 24px;
              font-weight: 500;
            }
  
            .location {
              display: flex;
              justify-content: space-between;
              img {
                width: 10%;
              }
              h6 {
                color: #a3aed0;
                font-size: 14px;
              }
            }
          }
        }
        .lowerBox {
          display: flex;
          justify-content: center;
          gap: 30px;
  
          .child {
            text-align: center;
  
            span {
              color: #a3aed0;
              font-size: 14px;
              font-weight: 400;
            }
  
            h6 {
              font-weight: 700;
              font-size: 22px;
            }
          }
        }
      }
  
      .line {
        height: 90%;
        background-color: $greyStroke;
        width: 2px;
        align-items: flex-start;
      }
  
      .rightSide {
        height: 90%;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: flex-start;
        gap: 15px;
  
        span {
          font-weight: 500;
          color: rgba(28, 28, 28, 1);
        }
  
        .detailsBox {
          display: flex;
          align-items: flex-start;
          gap: 6px;
          img {
            height: 60%;
          }
  
          span {
            color: rgba(28, 28, 28, 0.4);
            font-size: 12px;
          }
          h6 {
            font-size: 13px;
          }
          .rightDescription {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }
      }
    }
  
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
  
      .location {
        padding: 0.2rem 0.8rem;
        background-color: #e4f3ec;
        border-radius: 50px;
        color: $primeryColor;
      }
      .dashboard {
        padding: 0.2rem 0.8rem;
        background-color: $primeryColor;
        border-radius: 50px;
        color: #ffffff;
        font-weight: 600;
      }
    }
  }
  