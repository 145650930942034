@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.branchOuter{
    @include flexProp (row, center, flex-start, 20px, 0px, 0px, 0px, 0px);

    .branchBox{
        @include flexProp (row, space-between, center, 20px, 20px, 20px, 20px, 20px);
            border-radius: 20px;
            background: $whiteColor;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
            width: 50%;
            position: relative;
            height: 500px;

            .branchLeft{
                @include flexProp (column, flex-start, center, 20px, 20px, 20px, 60px, 20px);
                 width: 50%;
                 border-right: 1px solid $greyStroke;
                img{
                    width: 120px;
                }

                .branchName{
                    @include flexProp (column, center, center, 0px, 0px, 0px, 0px, 0px);
                }

                .branchFeatureList{
                    @include flexProp (row, space-around, center, 10px, 0px, 0px, 0px, 0px);
                    width: 100%;
                    .branchFeature{
                        @include flexProp (column, center, center, 0px, 0px, 0px, 0px, 0px);
                    }
                }
            }

            .branchRight{
                width: 50%;
            

                .branchDetailList{
                    .branchDetailListItem{
                        @include flexProp (row, flex-start, center, 10px, 10px, 0px, 10px, 0px);
                        .branchDetailIcon{
                            @include flexProp (row, center, center, 0px, 0px, 0px, 0px, 0px);
                            width: 26px;
                            height: 26px;
                            background-color: $lightGreen;
                            border-radius: 8px;
                            color: $primeryColor;
                        }
                    }
                }
            }
    }
}