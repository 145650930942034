@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

::-webkit-scrollbar {
  display: none;
}

.outerContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 84vh;

  .profileDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32%;
  }
}

.container {
  @include flex-start-center;
  width: 49%;
  height: 100%;
  border: 1px solid $borderColor;
  border-radius: 16px;
  padding: 0.5rem;
  font-family: Inter;

  .imageContainer {
    @include flex-start-center;
    height: 100%;
    background-color: #f3f3f3;
    border-radius: 10px;

    img {
      width: 100%;
    }
  }

  .ownerDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    h2 {
      font-size: 24px;
      margin-bottom: 5px;
      margin-left: 5px;
    }
    .social {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      font-size: 14px;
    }
  }

  .numberRating {
    width: 40%;
    height: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: Manrope;
      font-weight: 800;
      font-size: 60px;
    }

    button {
      width: 90%;
      font-size: 12px;
      border-radius: 20px;
      font-family: Manrope;
      font-weight: 700;
      color: #f3f3f3;
      background-color: $primeryColor;
      padding: 6px 15px;
    }
  }
}

.tableContainer {
  width: 100%;
  // height: 35%;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.artistContainer {
  width: 60%;
  height: 100%;
  border: 1px solid $borderColor;
  border-radius: 16px;
  padding: 0.8rem;
  font-family: Inter;
}

.heading {
  @include flex-between-center;
  width: 100%;

  h3 {
    font-size: 15px;
    font-weight: 600;
  }

  button {
    width: 100px;
    background-color: $lightGreen;
    border-radius: 20px;
    padding: 0.4rem;
    color: $primeryColor;
    font-size: 14px;
    font-weight: 600;
  }
}

.customerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 38%;
  height: 100%;
  border: 1px solid $borderColor;
  border-radius: 16px;
  padding: 0.8rem;
  gap: 15px;
  font-family: Inter;

  .customerList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .customer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem 0.5rem;
      border-bottom: 1px solid $borderColor;

      // img {
      //   margin-right: 10px;
      // }

      span {
        font-size: 12px;
        margin-left: 10px;
      }
      h6 {
        font-size: 12px;
        color: $primeryColor;
        font-weight: 500;
      }
    }
  }
}
